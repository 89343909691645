import React, { useContext } from 'react'
import DashboardWrapper from '../../../components/BestSchoolsAwards/DashboardWrapper/DashboardWrapper'
import * as DashboardStyles from './Dashboard.module.scss'
import * as ContentStyles from '../../../components/BestSchoolsAwards/Content/Content.module.scss'
import { Helmet } from 'react-helmet'
import LatestEntries from '../../../components/BestSchoolsAwards/LatestEntries/LatestEntries'
import Categories from '../../../components/BestSchoolsAwards/Categories/Categories'
import { SchoolAwardsContext, SchoolAwardsStates } from '../../../lib/utils'
import { Link } from 'gatsby'

const BSADashboard = () => {
  const SchoolContext = useContext(SchoolAwardsContext)
  const titleText =
    SchoolContext === SchoolAwardsStates.FINALISTS
      ? 'Meet the Finalists'
      : 'Welcome!'
  return (
    <>
      <Helmet>
        <title>
          Muddy Stilettos&apos; Best Schools Awards | Muddy Stilettos
        </title>
      </Helmet>
      <DashboardWrapper>
        <div className={DashboardStyles.Intro}>
          <h1>{titleText}</h1>

          {SchoolContext === SchoolAwardsStates.OPEN ? (
            <>
              <p>
                <strong>
                  Welcome to the Muddy Stilettos Best School Awards 2024
                  dashboard.
                </strong>
                {` `}
                From here you can create, save and submit your entries into the
                2024 Muddy School Awards.{' '}
              </p>
              <p>
                Please use the following links for a reminder of our{' '}
                <a href="/best-schools-awards/terms-conditions">
                  Terms of Entry
                </a>
                , and <a href="/best-schools-awards/faqs">FAQs</a>, or feel free
                to go to our{' '}
                <a href="/best-schools-awards/">Best Schools Awards intro</a>{' '}
                for a recap.
              </p>
              <p>
                Click <a href="https://muddystilettos.co.uk">here</a> to return
                to the Muddy Stilettos homepage.
              </p>
              <p>
                <strong>
                  Need a bit more time? Entry deadline has now been extended to
                  3pm, 23 APRIL 2024.
                </strong>
              </p>
              <div className={ContentStyles.GenericButtonGroup}>
                <a
                  href={`/best-schools-awards/dashboard/enter/`}
                  className={ContentStyles.GenericButton}
                >
                  Enter the Awards
                </a>
                <a
                  href={`/best-schools-awards/form/muddy-stilettos-best-schools-awards-application-2024.docx`}
                  target={`_BLANK`}
                  className={ContentStyles.GenericButton}
                >
                  Download the Entry Form
                </a>
              </div>
            </>
          ) : (
            <>
              {SchoolContext === SchoolAwardsStates.WINNERS ? (
                <>
                  <p>
                    We are thrilled to announce Muddy’s 2024 Best Schools Awards
                    winners, finalists and highly commended.
                  </p>
                  <p>
                    Go to the{' '}
                    <Link to={`/best-schools-awards/results/`}>Results</Link>{' '}
                    page to find out who’s TOP OF THE CLASS, plus all the
                    FINALISTS and schools that have been HIGHLY COMMENDED by the
                    judges. Gold stars all round!
                  </p>
                  <p>Thank you to everyone that took part.</p>
                  <p>
                    If you are interested in working with Muddy Stilettos Best
                    Schools Guide in the future please contact{` `}
                    <a href="mailto:katie@muddystilettos.co.uk">
                      katie@muddystilettos.co.uk
                    </a>
                    .
                  </p>
                  <div className={ContentStyles.GenericButtonGroup}>
                    <a
                      href={`/best-schools-awards/results/`}
                      className={ContentStyles.GenericButton}
                    >
                      Meet the Winners
                    </a>
                  </div>
                </>
              ) : (
                <>
                  {SchoolContext === SchoolAwardsStates.FINALISTS ? (
                    <>
                      <p>
                        We are thrilled to announce Muddy’s 2024 Best Schools
                        Awards Finalists.
                      </p>

                      <p>
                        Our expert judging panel have selected up to five
                        FINALISTS per category, plus schools that have been
                        HIGHLY COMMENDED. Go to the{' '}
                        <Link to={`/best-schools-awards/results/`}>
                          Results
                        </Link>{' '}
                        page to find out who has made it to the finals.
                      </p>

                      <p>
                        <strong>
                          The Winners will be announced on 27 June 2024.
                        </strong>
                      </p>

                      <p>Thank you to everyone that took part.</p>

                      <p>
                        If you are interested in working with Muddy Stilettos
                        Best Schools Guide in the future please contact{` `}
                        <a href="mailto:katie@muddystilettos.co.uk">
                          <strong>katie@muddystilettos.co.uk</strong>
                        </a>
                      </p>

                      <div className={ContentStyles.GenericButtonGroup}>
                        <a
                          href={`/best-schools-awards/results/`}
                          className={ContentStyles.GenericButton}
                        >
                          View Finalists
                        </a>
                      </div>
                    </>
                  ) : (
                    <>
                      <p>
                        <strong>THE SCHOOL AWARDS 2024 ARE NOW CLOSED</strong>
                      </p>
                      <p>
                        5 Highly Commended and up to 5 Finalist Schools per
                        category will be announced on 20th June and these
                        schools will enter a second round of judging. Watch this
                        space!
                      </p>
                      <p>
                        Please use the following links for a reminder of our{' '}
                        <a href="/best-schools-awards/terms-conditions">
                          Terms of Entry
                        </a>
                        , and <a href="/best-schools-awards/faqs">FAQs</a>, or
                        feel free to go to our{' '}
                        <a href="/best-schools-awards/">
                          Best Schools Awards intro
                        </a>{' '}
                        for a recap.
                      </p>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
        <section className={DashboardStyles.Entries}>
          <header>
            <h3>Your entries</h3>
            {/*<span className="see-all">
              <span className="see-all__icon">{/* <EyeIcon /></span>
              <Link to="/best-schools-awards/dashboard/entries">see all</Link>
            </span> */}
          </header>
          <LatestEntries />
        </section>
        <Categories partialListing dashboardListing />
      </DashboardWrapper>
    </>
  )
}

export default BSADashboard
