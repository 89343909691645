// extracted by mini-css-extract-plugin
export var Complete = "LatestEntries-module--Complete--94381";
export var Entry = "LatestEntries-module--Entry--8af1c";
export var EntryCard = "LatestEntries-module--EntryCard--b9188";
export var EntryCategory = "LatestEntries-module--EntryCategory--b0cd6";
export var EntryEdit = "LatestEntries-module--EntryEdit--91934";
export var EntryIcon = "LatestEntries-module--EntryIcon--e7ab7";
export var EntryIconImage = "LatestEntries-module--EntryIconImage--49d42";
export var EntrySchoolDept = "LatestEntries-module--EntrySchoolDept--fe9b4";
export var EntryText = "LatestEntries-module--EntryText--10381";
export var EntryTitle = "LatestEntries-module--EntryTitle--87964";
export var EntryYearNavigation = "LatestEntries-module--EntryYearNavigation--8b6db";