export const listEntries = async (user: string) => {
  try {
    const dataBody = new FormData()
    dataBody.append('usr', JSON.stringify(user))
    const url = 'https://dev.muddystilettos.co.uk/wp-json/muddy/school-entries/'
    const res = await fetch(url, {
      method: 'POST',
      body: dataBody
    })

    const json = await res.json()
    console.log('json', json)
    return json
  } catch (e: any) {
    return { success: 0, message: e.message }
  }
}
