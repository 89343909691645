import React, { useState, useContext, useEffect } from 'react'
import { LoginContext } from '../../../lib/utils'
import { Link } from 'gatsby'
import { listEntries } from '../../../api/best-school-awards/get-entries'
import * as EntriesStyles from './LatestEntries.module.scss'
import { Cats } from '../../../lib/data/BestSchoolAwards'

const LatestEntries: React.FC = () => {
  const [error, setError] = useState({ state: false, msg: '' })
  const [loading, setLoading] = useState(true)
  const [entries, setEntries] = useState([])
  const [shownYear, setShownYear] = useState<string | number>(0)
  const [foundUser, setFoundUser] = useState(false)
  const { usr: currentUser } = useContext(LoginContext)

  useEffect(() => {
    if (currentUser && currentUser.viewer?.email) {
      setFoundUser(true)
    }
  }, [currentUser])

  useEffect(() => {
    if (foundUser) {
      listEntries(currentUser?.viewer.email ?? '').then(result => {
        if (result.success === false) {
          setError({ state: true, msg: result.data })
        } else {
          const groupedResults = result.reduce((r, a) => {
            r[a.year] = r[a.year] || []
            r[a.year].push(a)
            return r
          }, Object.create(null))

          setEntries(groupedResults)
          const years = Object.keys(groupedResults)
          setShownYear(years.slice(-1).pop() ?? 0)
        }
        setLoading(false)
      })
    }
  }, [foundUser])

  const isEntrySubmitted = (submission: any) => {
    return submission == 1 ? true : false
  }

  if (loading) {
    return <p>Loading...</p>
  }
  if (error.state && error.msg === 'Failed to validate user') {
    return (
      <div className="entry is-empty">
        <p>{error.msg}</p>
      </div>
    )
  }

  if (entries.length === 0) {
    return (
      <div className={EntriesStyles.Entry}>
        <p>
          You haven&apos;t created an entry yet.{' '}
          <Link to="/best-schools-awards/dashboard/enter">Get started!</Link>
        </p>
      </div>
    )
  }

  return (
    <>
      {/* <div className={EntriesStyles.EntryYearNavigation}>
        {Object.keys(entries).map(yr => (
          <div
            key={yr}
            className={shownYear === yr && 'active'}
            onClick={() => setShownYear(yr)}
          >
            {yr}
          </div>
        ))}
      </div> */}
      {entries.length === 0 && (
        <div className={EntriesStyles.Entry}>
          <p>
            <strong>
              You haven&apos;t created an entry yet.{' '}
              <Link to="/best-schools-awards/dashboard/submit">
                Get started!
              </Link>
            </strong>
          </p>
        </div>
      )}
      {Object.keys(entries).map(yr => (
        <div
          key={yr}
          aria-hidden={yr !== shownYear}
          className={EntriesStyles.Entry}
        >
          {entries[yr].length > 0 &&
            entries[yr].map((entry, i) => {
              const cat = Cats.find(cat => cat.ID === entry.catid)
              return (
                <div className={EntriesStyles.EntryCard} key={i}>
                  <div className={EntriesStyles.EntryIcon}>
                    <div className={EntriesStyles.EntryIconImage}>
                      <img src={cat?.image} alt={entry.categoryname} />
                    </div>
                  </div>
                  <div className={EntriesStyles.EntryText}>
                    {/* <span
                      className={
                        'entry__completed ' +
                        (entryPercent == '100' ? 'is--completed' : '')
                      }
                    >
                      {entryPercent}%
                    </span> */}
                    {/* <div
                      className={
                        'entry__barometer ' +
                        (entryPercent == '100' ? 'is--completed' : '')
                      }
                      style={{ '--percent-completed': entryPercent + '% ' }}
                    ></div> */}
                    <p
                      className={EntriesStyles.EntryTitle}
                      dangerouslySetInnerHTML={{ __html: entry.title }}
                    />
                    <p className={EntriesStyles.EntryCategory}>
                      {cat?.title}
                      {entry.schooldepartment && (
                        <span className={EntriesStyles.EntrySchoolDept}>
                          {` `}- {entry.schooldepartment}
                        </span>
                      )}
                    </p>
                  </div>
                  {!isEntrySubmitted(entry.submitted) ? (
                    <Link
                      to={'/best-schools-awards/dashboard/enter?id=' + entry.id}
                      className={EntriesStyles.EntryEdit}
                    >
                      Edit
                    </Link>
                  ) : (
                    <span
                      className={`${EntriesStyles.EntryEdit} ${EntriesStyles.Complete}`}
                    >
                      Submitted
                    </span>
                  )}
                </div>
              )
            })}
        </div>
      ))}
    </>
  )
}

export default LatestEntries
